<template>
<!-- 登录查看价格组件 -->
  <h5 v-if="showClass">{{priceData}}</h5>
  <h5 v-else @click.stop="loginshow">{{priceData}}</h5>

  <div class="logindialog" v-if="!showClass" @click.stop="">
    <el-dialog
      v-model="centerDialogVisible"
      :title="$t('m.index.quick_login')"
      width="30%"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
    >
      <div>
        <IDLoginVue :quickLogin="true"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { getStore } from '../tools/storage'
import IDLoginVue from '../views/loginandregister/login/IDLogin.vue'
import i18n from '@/tools/i18n.js'
const { t } = i18n.global
export default {
  name: 'Nologindata',
  data () {
    return {
      priceData: ref(''),
      isprice: ref(''),
      showClass: ref(false),
      centerDialogVisible: ref(false),
      isLogin: false
    }
  },
  props: {
    price: null
  },
  components: { IDLoginVue },
  methods: {
    loginshow () {
      // 登录弹窗
      console.log('触发要登陆')
      this.centerDialogVisible = true
      // console.log(centerDialogVisible)
    }
  },
  watch: {
    price: {
      // immediate: true,
      handler (val) {
        // console.log(val)
        // this.priceData = val
        if (this.isLogin) {
          // 已登录
          this.priceData = val || '***'
          this.showClass = true
        } else {
          // 未登录
          this.showClass = false
          this.priceData = t('m.index.Login_to_view_prices') // 登录查看价格
        }
      }
    }
  },
  mounted () {
    // this.priceData = this.$props.price
    this.isLogin = !!((getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{'))
    // console.log(this.isLogin)
    if ((getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null) {
      // 已登录
      // console.log(this.$props.price)
      // console.log(this.priceData)
      // this.isprice = this.$props.price || '***'
      this.priceData = this.$props.price || '***'
      this.showClass = true
    } else {
      // 未登录
      this.showClass = false
      // this.isprice = t('m.index.Login_to_view_prices') // 登录查看价格
      this.priceData = t('m.index.Login_to_view_prices') // 登录查看价格
    }
  },
  setup (props, ctx) {
    // const datacop = getCurrentInstance()
    // const centerDialogVisible = ref(false)
    // let isprice = null
    // let showClass = false
    // // console.log(props.price)
    // // const token = getStore('token')
    // // const usermessage = getStore('usermessage')
    // if ((getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null) {
    //   // 已登录
    //   console.log(props.price)
    //   console.log(datacop)
    //   isprice = props.price || '***'
    //   showClass = true
    // } else {
    //   // 未登录
    //   showClass = false
    //   isprice = t('m.index.Login_to_view_prices') // 登录查看价格
    // // }
    // const loginshow = () => {
    //   // 登录弹窗
    //   console.log('触发要登陆')
    //   centerDialogVisible.value = true
    //   // console.log(centerDialogVisible)
    // }
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
h5{
  display: inline-block;
}
.logindialog{
  cursor: auto;
  display: inline-block;
}
</style>
