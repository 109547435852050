<template>
  <div class="nav">
    <ul>
      <li v-for="(item,index) in headernav" :key="index"
      @click="this.$router.push(item.path)">
        {{item.text}}
      </li>
    </ul>
    <div id="search" v-if="!this.$props.allNo">
      <div class="logosearch">
        <div id="logo">
          <img @click="this.$router.push('/')" src="../assets/logo2.png" alt="">
        </div>
        <div class="cut" v-if="!this.$props.sear">
          <div id="sear">
            <!-- <input id="searin" type="text" placeholder="品牌/品种/产地"> -->
            <el-input id="searin" v-model="searValue" :placeholder="$t('m.search.commodity')"></el-input>
            <button class="sou" @click="sou">{{$t('m.search.search')}}</button>
          </div>
        </div>
        <div class="my_auction" v-if="this.$props.btn">
          <button class="myau"
          @click="this.$router.push(this.$props.btn.to)">{{this.$props.btn.title}}</button>
        </div>
        <div class="my_auction" v-if="this.$props.barterBtn">
          <button class="yihuo"
          @click="this.$router.push(this.$props.barterBtn.to)">{{this.$props.barterBtn.title}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '../tools/bus'
import { getStore } from '../tools/storage'

export default {
  name: 'HeaderBase',
  data () {
    return {
      headernav: [
        { text: this.$t('m.Header.home_page'), path: '/' },
        { text: this.$t('m.search.login'), path: '/loginandregister' },
        { text: this.$t('m.Header.mobile_terminal'), path: '/' }
      ],
      searValue: '' // 搜索框内容
    }
  },
  mounted () {
    this.searValue = this.$props.sear_Value
    if (this.$props !== undefined) {
      // console.log(11)
    }
    if (getStore('usermessage')) {
      this.headernav = [
        { text: this.$t('m.Header.home_page'), path: '/' },
        { text: this.$t('m.Header.login_again'), path: '/loginandregister' },
        { text: this.$t('m.Header.mobile_terminal'), path: '/' }
      ]
    }
  },
  methods: {
    sou () {
      this.$router.push({
        path: '/search',
        query: { sedata: '0', data: this.searValue }
      })
      emitter.emit('searchHeaderBase', { sedata: '0', data: this.searValue })
    }
  },
  props: ['btn', 'barterBtn', 'sear', 'sear_Value', 'allNo'],
  setup (props, ctx) {
    // console.log(props.my_auction)
    return {

    }
  }
}
</script>

<style lang="scss" >
@import '../style/viriables.scss';
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            cursor: pointer;
            margin-right: 80px;
            z-index: 9999;
            width: 145px;
          }
        }
        .cut{
          padding:0 10px;
          #sear{
            width: 659px;
            flex: 1;
            display: flex;
            align-items: center;
            #searin{
              // border: 1px solid #FFFFFF;
              box-sizing: border-box;
              background: 0;
              flex: 1;
              height: 40px;
              padding: 0 15px;
              outline: none;
              border: 1px solid;
              border-image: linear-gradient(0deg, #280E01, #673000) 10 10;
            }
            #searin::-webkit-input-placeholder {
              color: #999999;
            }
            #searin:-moz-placeholder {
              color: #999999;
            }
            #searin:-ms-input-placeholder {
              color: #999999;
            }
            #searin::-ms-input-placeholder{
              color: #999999;
            }
            .sou{
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #FFFFFF;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
              width: 120px;
              height: 40px;
            }
          }
        }
        .my_auction{
          .myau,.yihuo{
            width: 120px;
            height: 40px;
            border: 1px solid #270F04;
            color: #633118;
            border-radius: 2px;
            font-size: 14px;
            line-height: 39px;
            background: linear-gradient(0deg, #270F04 0%, #633118 100%);
            background-clip: text;
          }
          .myau:hover,.yihuo:hover{
            background: rgb(231, 231, 231);
            color: #633118;
          }
        }
      }
    }
  }
</style>
